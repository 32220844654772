
import {
    RowDelete16
} from '@carbon/icons-react'
import { useCallback, useEffect, useMemo, useState } from 'react';
import Util from '../util/Util';
import Button from './Button';


const Root = ({ minimal, children }) => (
    <div className={"no-input-border-2 " + (minimal ? "minimal-input " : "")} style={{ background: '#f4f4f4', width: '100%', }}>
        {children}
    </div>
)

const Header = ({ minimal, columns, readonly }) => {
    return (
        <div>
            <div style={{ display: 'flex', background: 'black', color: 'white', borderBottom: 'solid', borderColor: 'black', borderWidth: 2, paddingTop: '0.75rem', paddingBottom: '0.15rem' }}>
                {columns.map(column => (
                    <div key={column.title + '-key'} style={{ flex: column.flex, width: column.width, paddingLeft: '0rem', display: 'flex' }}>
                        <div style={{ width: minimal ? 5 : '1rem' }} />
                        <h6>{column.title}</h6>
                    </div>
                ))}
                {!readonly && <div style={{ width: 40, height: 0, }} />}
            </div>
        </div>
    )
}

const ColumnEntry = ({ column, useEntry, setMultiProp, readonly }) => {
    const [value, setValue] = useEntry(column.key)
    return (
        <div style={{ flex: column.flex, width: column.width, paddingLeft: '0rem', display: 'flex' }}>
            <column.Component useEntry={useEntry} value={value} setValue={setValue} setMultiProp={setMultiProp} readonly={readonly} />
        </div>
    )
}



const Entry = ({ columns, readonly, onRemove, item, setProp, setMultiProp, onNew }) => {
    const useEntry = useCallback((key) => {
        const value = item[key];
        return [value, arg => setProp(key, arg)]
    }, [item, setProp])

    return (
        <div style={{ display: 'flex', borderBottom: 'solid', borderBottomWidth: 1, borderColor: '#00000020' }}>
            {columns.map((column, i) => (
                <ColumnEntry key={column.key + '-key-entry'} column={column} useEntry={useEntry} setMultiProp={setMultiProp} readonly={readonly} />
            ))}
            {!readonly &&
                <Button hasIconOnly iconDescription="Remove Row" onClick={onRemove} renderIcon={RowDelete16} kind="danger"
                    size="sm" disabled={onRemove === undefined}
                    style={{ width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }} tooltipPosition="left" tooltipAlignment="end" />}
        </div>
    )
}

export function TableInput({ minimal, columns, readonly, value, setValue, hideDummy, defaultItemValue = {} }) {
    //const [newEntry, setNewEntry] = useState(() => ({ id: Util.newTempId() }));
    const items = useMemo(() => [...value, { ...defaultItemValue, id: Util.newTempId(), _dummy: true }], [value]);

    const onRemoveItem = item => setValue(prev => prev.filter($ => $.id !== item.id))
    const setProp = (item, prop, arg) => setValue(prev => prev.map($ => $.id === item.id ? ({
        ...$, [prop]: typeof arg === "function" ? arg($[prop]) : arg
    }) : $))
    const setMultiProp = (item, arg) => setValue(prev => prev.map($ => $.id === item.id ? ({
        ...$, ...(typeof arg === "function" ? arg($) : arg)
    }) : $))
    const onNewItem = (id, prop, arg) => {
        setValue(prev => [...prev, {
            ...defaultItemValue, id,
            [prop]: typeof arg === "function" ? arg(defaultItemValue[prop]) : arg,
        }])
    }
    const onNewItemWithMultiProp = (id, arg) => {
        setValue(prev => [...prev, {
            ...defaultItemValue, id,
            ...(typeof arg === "function" ? arg({ ...defaultItemValue, id }) : arg)
        }])
    }
    return (
        <Root {...{ minimal }}>
            <Header {...{ minimal, columns, readonly }} />
            {items.filter($ => hideDummy ? !$._dummy : true).map((item,) => (
                <Entry
                    key={item.id}
                    item={item}
                    onRemove={!item._dummy ? (() => onRemoveItem(item)) : undefined}
                    setProp={(prop, arg) => {
                        if (item._dummy) {
                            onNewItem(item.id, prop, arg)
                        } else {
                            setProp(item, prop, arg)
                        }
                    }}
                    setMultiProp={arg => {
                        if (item._dummy) {
                            onNewItemWithMultiProp(item.id, arg)
                        } else {
                            setMultiProp(item, arg)
                        }
                    }}
                    {...{ columns, readonly }} />
            ))}
        </Root>
    )
}