import React, { useEffect, useMemo, useRef } from 'react'
import { useState } from 'react';


import { ChevronDown16, CaretRight16, Close16, InventoryManagement20 } from '@carbon/icons-react'
import { Button, InlineLoading, TextInput } from 'carbon-components-react';
import Util from '../util/Util';
import { useClickOutside } from '../hooks/useClickOutside';
import Api from '../session/Api';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDebounce } from '../hooks/useDebounce';
import { OBJECT_TYPE_PRODUCT } from '../constants/ObjectTypes';
import ProfilePic from './ProfilePic';

const useLoadItems = ({ loader, key, searchValue }) => {
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        setPage(0);
        setLoading(false);
        setItems([]);
        setHasNextPage(true);
        setError(undefined)
    }, [searchValue, key])

    const loadMore = () => {
        setLoading(true);
        loader(searchValue, page, response => {
            if (response.status === true) {
                setPage(page + 1);
                setLoading(false);
                setItems(items => [...items, ...response.payload.items])
                setHasNextPage(response.payload.hasMore);
                setError(undefined);
            } else {
                setLoading(false);
                setError(response.message);
            }
        })
    }

    return {
        loading, items, hasNextPage, loadMore, error
    }
}

const LoadingEntry = ({ noPadding }) => (
    <div className="bx--list-box__menu-item" style={{ display: 'flex', alignItems: 'center', padding: noPadding ? 0 : '1rem', height: 'unset', minHeight: 45, paddingLeft: '1rem' }}>
        <div className="really_centered-progress-bar" style={{ width: '100%', gap: '0.25rem', paddingBlock: '0.5rem', borderBottom: '1px solid #00000020', display: 'flex', alignItems: 'center', gap: '0.25rem', }}>
            <InlineLoading style={{ width: 'unset' }} />
            <p style={{ fontSize: 14, opacity: 0.65 }}>Loading...</p>
        </div>
    </div>
)

export function createListSelector(ItemView, loader, itemToString) {
    return (props) => <ListSelector {...props} ItemView={ItemView} loader={loader} itemToString={itemToString} />
}

export const ListSelector = ({ ItemView, placeholder = "Select...", light, noPadding = true, readonly, loader, value, setValue, itemToString }) => {
    const [visible, setVisible] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [key, setKey] = useState(() => Util.newTempId())
    const debouncedSearch = useDebounce(searchValue, 500)

    const shouldSearch = Util.isStringExists(searchValue) && (!value || itemToString(value) !== searchValue)
    const { loading, items, hasNextPage, error, loadMore } = useLoadItems({
        loader, key, searchValue: shouldSearch ? debouncedSearch : ""
    });

    const wrapperRef = useClickOutside(() => setVisible(false))
    const inputRef = useRef();

    useEffect(() => {
        if (!visible && !value) {
            setKey(Util.newTempId());
            setSearchValue("");
        } else if (!visible && value) {
            setKey(Util.newTempId());
            setSearchValue(itemToString(value))
        }
    }, [visible, value])

    const onSelect = item => {
        setValue(item);
        setSearchValue(itemToString(item))
        setVisible(false);
    }

    const onWrapperClick = () => {
        setVisible(true)
        inputRef?.current?.focus()
    }

    const onClearBtn = () => {
        setValue(null)
        setSearchValue("")
    }

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        disabled: !!error,
        rootMargin: '0px 0px 400px 0px',
    });

    return (
        <div ref={wrapperRef} style={{ width: '100%', height: '100%', position: 'relative' }} onKeyDown={e => {
            if (e.key === "Tab") {
                setVisible(false)
            }
        }}>
            <div onClick={!readonly ? onWrapperClick : undefined} className="bx--text-input" style={{ position: 'relative', height: '100%', height: '100%', background: light ? 'white' : '#f4f4f4', display: 'flex', alignItems: 'center', cursor: !readonly ? 'pointer' : undefined }}>
                <input ref={inputRef}
                    style={{ all: 'unset', fontSize: 14, flex: 1, height: 40 }} value={searchValue} placeholder={placeholder} onChange={e => setSearchValue(e.target.value)}></input>

                {value !== undefined && !readonly && (
                    <div onClick={onClearBtn} style={{ height: '100%', marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', transition: '250ms', transform: visible ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                        <Close16 />
                    </div>
                )}

                {!readonly && <div
                    style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', transition: '250ms', transform: visible ? 'rotate(180deg)' : 'rotate(0deg)' }}>
                    <ChevronDown16 />
                </div>}
            </div>

            {visible && (
                <div ref={rootRef} className="bx--list-box__menu" style={{ width: '100%', height: 250, animation: 'custom-combo-box-drop-down 250ms' }}>
                    {items.map(item => (
                        <div key={item.id} onClick={!readonly ? (() => onSelect(item)) : undefined} className="bx--list-box__menu-item" style={{ display: 'flex', alignItems: 'center', padding: noPadding ? 0 : '1rem', height: 'unset', minHeight: 45, paddingLeft: '1rem' }}>
                            <ItemView item={item} />
                        </div>
                    ))}

                    <div ref={sentryRef} />

                    {(loading || hasNextPage) ? (
                        <LoadingEntry noPadding={noPadding} />
                    ) : (
                        <div className="really_centered-progress-bar" style={{ height: '3rem', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
                            <p style={{ fontSize: 12, opacity: 0.65 }}>{items.length ? 'No more items' : 'No items'}</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}





export const ProductListSelector = createListSelector(({ item }) => (
    <div style={{ width: '100%', gap: '0.25rem', paddingBlock: '0.5rem', borderBottom: '1px solid #00000020', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
        <ProfilePic notProfile size={24} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT, item.id)} />
        <div>
            <p style={{ fontSize: 14, opacity: 1, flex: 1 }}>{item.name}</p>
            <p style={{ fontSize: 12, opacity: 0.65 }}>{item.articleNo}</p>
        </div>
    </div>
), Api.searchProduct2.bind(Api), $ => $.name)


export const ShipmentListSelector = createListSelector(({ item }) => (
    <div style={{ width: '100%', gap: '0.25rem', paddingBlock: '0.5rem', borderBottom: '1px solid #00000020', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
        <div>
            <p style={{ fontSize: 14, opacity: 1, flex: 1 }}>Job {item.shipmentNo}</p>
            <p style={{ fontSize: 12, opacity: 0.65 }}>{item.invoiceCustomerIdName}</p>
        </div>
    </div>
), Api.searchShipment.bind(Api), $ => $.shipmentNo)

export const SellableListSelector = createListSelector(({ item }) => (
    <div style={{ width: '100%', gap: '0.25rem', paddingBlock: '0.5rem', borderBottom: '1px solid #00000020', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
        <ProfilePic notProfile size={24} src={Api.getThumbnail(item.type, item.id)} />
        <div>
            <p style={{ fontSize: 14, opacity: 1, flex: 1 }}>{item.name}</p>
            <p style={{ fontSize: 12, opacity: 0.65 }}>AED {Util.formatMoney(item.amount)}</p>
        </div>
    </div>
), Api.searchSellable.bind(Api), $ => $.name)

export const FnbEventSellableListSelector = createListSelector(({ item }) => (
    <div style={{ width: '100%', gap: '0.25rem', paddingBlock: '0.5rem', borderBottom: '1px solid #00000020', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
        <ProfilePic notProfile size={24} src={Api.getThumbnail(item.type, item.id)} />
        <div>
            <p style={{ fontSize: 14, opacity: 1, flex: 1 }}>{item.name}</p>
            <p style={{ fontSize: 12, opacity: 0.65 }}>AED {Util.formatMoney(item.amount)}</p>
        </div>
    </div>
), Api.searchFnbEventSellable.bind(Api), $ => $.name)


// export const V2BillItemListSelector = createListSelector(({ item }) => (
//     <div style={{ width: '100%', gap: '0.25rem', paddingBlock: '0.5rem', borderBottom: '1px solid #00000020', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
//         <ProfilePic notProfile size={24} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT, item.id)} />
//         <div>
//             <p style={{ fontSize: 14, opacity: 1, flex: 1 }}>{item.name}</p>
//             <p style={{ fontSize: 12, opacity: 0.65 }}>{item.articleNo}</p>
//         </div>
//     </div>
// ), Api.searchProduct2.bind(Api), $ => $.name)